import { GetDocRes } from "@/types/docs";
import { Pencil, SquareUserRound } from "lucide-react";
import { Link } from "wouter";
import { Button } from "../ui/button";

export const UpdateDocButton = ({
	doc,
	link,
}: {
	link?: boolean;
	doc: GetDocRes;
}) => {
	const style = link ? "" : "bg-indigo-500 hover:bg-indigo-600 text-white";
	return (
		<Button
			asChild
			className={style}
			size="sm"
			variant={link ? "link" : "default"}
		>
			<Link to={`/docs/edit/${doc.id}`}>
				<Pencil className="mr-2" size="15" />
				Update with AI
			</Link>
		</Button>
	);
};

export const RevisionsButton = ({
	doc,
	versionId,
}: {
	doc: GetDocRes;
	versionId?: string;
}) => {
	const currentVersionId = versionId ?? doc?.defaultVersion;

	if (!doc?.versions || doc.versions.length === 1) {
		return null;
	}

	return (
		<div className="flex items-center gap-2 text-sm font-semibold">
			Revisions
			{doc?.versions?.map((vid, index) =>
				vid === currentVersionId ? (
					<div className="px-2 rounded-md bg-indigo-400 text-white" key={vid}>
						{index + 1}
					</div>
				) : (
					<Link
						className="bg-stone-100 hover:bg-stone-200 rounded-md px-2"
						key={vid}
						to={`/docs/${doc.id}/${vid}`}
					>
						{index + 1}
					</Link>
				),
			)}
		</div>
	);
};

export const DocUserInfo = ({ doc }: { doc: GetDocRes }) => {
	if (!doc?.user) {
		return null;
	}
	return (
		<div className="flex items-center gap-2 p-4 text-sm">
			<SquareUserRound size={20} />
			{doc.user.name}
		</div>
	);
};
