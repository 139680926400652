import { sendBeacon } from "@/lib/utils.ts";
import { type PropsWithChildren, useEffect } from "react";
import { useLocation } from "wouter";

import { Nav } from "./Nav.tsx";
import { SigninBanner } from "./SigninBanner.tsx";
import { Toaster } from "./ui/toaster.tsx";
import { TooltipProvider } from "./ui/tooltip.tsx";

export const Layout = ({ children }: PropsWithChildren) => {
	const [location] = useLocation();

	useEffect(() => {
		sendBeacon("Page Viewed", { Location: location });
	}, [location]);

	return (
		<div className="bg-muted/40 font-sans antialiased">
			<Nav />

			<div className="px-2 md:px-4 md:container w-full space-y-4 mt-6">
				<TooltipProvider>{children}</TooltipProvider>

				<Toaster />
				<SigninBanner />
			</div>

			<div className="p-4 text-center border-t">GrantOrb Inc.</div>
		</div>
	);
};
