import { Title } from "@/components/Title.js";
import type { ListDocsRes } from "@/types/docs.js";
import { InfoIcon } from "lucide-react";
import useSWR from "swr";

import { DocListItem, MoreListItem, PublishedDocListItem } from "./ListDocs.js";

interface ListDraftsProps {
	limit?: number;
}

export const ListDrafts = ({ limit }: ListDraftsProps) => {
	const limitCount = (limit ?? 100) + 1;
	const { data: docs } = useSWR<ListDocsRes>(`/a/drafts?limit=${limitCount}`, {
		onError: () => {},
	});

	if (!docs) {
		return null;
	}

	return (
		<div>
			<Title desc="Proposals still being worked on" text="Drafts" />

			{docs.length === 0 && (
				<div className="bg-white border border-dashed rounded-xl p-4 text-xl text-gray-600">
					<InfoIcon className="inline-block mr-2" />
					You have no proposal drafts saved.
				</div>
			)}

			<div className="grid gap-cols-1 md:grid-cols-3 gap-4">
				{docs
					.slice(0, limit)
					.map((doc) =>
						doc.type === "doc" ? (
							<DocListItem doc={doc} key={doc.id} pathPrefix="/drafts/" />
						) : (
							<PublishedDocListItem doc={doc} key={doc.id} />
						),
					)}

				{docs.length === limitCount && (
					<MoreListItem label="Drafts" path="/drafts" />
				)}
			</div>
		</div>
	);
};
