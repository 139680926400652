import { Title } from "@/components/Title";
import { TemplateTags } from "@/components/doc/TemplateTags";
import { Button } from "@/components/ui/button.js";
import {
	Card,
	CardContent,
	CardFooter,
	CardHeader,
	CardTitle,
} from "@/components/ui/card";
import { useToast } from "@/components/ui/use-toast";
import { postFetch } from "@/lib/fetchers";
import { cn, timeSince } from "@/lib/utils";
import type { ListDocsRes } from "@/types/docs.js";
import {
	ArrowRightSquare,
	Bookmark,
	InfoIcon,
	Share,
	SquareChevronRight,
} from "lucide-react";
import useSWR, { useSWRConfig } from "swr";
import useSWRMutation from "swr/mutation";
import { Link } from "wouter";

interface MoreListItemProps {
	label: string;
	path: string;
}

export const MoreListItem = ({ label, path }: MoreListItemProps) => (
	<Card className="border border-dashed border-indigo-100 shadow bg-background hover:border-indigo-500 rounded-lg p-4 flex flex-col items-center justify-center">
		<CardContent>
			<Link to={path}>
				<div className="flex items-center gap-2 text-center text-lg font-semibold tracking-tight">
					<span>More {label}</span>
					<SquareChevronRight size="30" />
				</div>
			</Link>
		</CardContent>
	</Card>
);

interface DocListItemProps {
	children?: React.ReactNode;
	doc: ListDocsRes[0];
	pathPrefix?: string;
}

export const DocListItem = ({
	children,
	doc,
	pathPrefix = "/docs/",
}: DocListItemProps) => {
	const { name, createdAt, id, template, title } = doc;
	return (
		<Card className="border border-indigo-100 flex flex-col justify-between shadow bg-background hover:border-indigo-500 rounded-lg p-4">
			<CardHeader>
				<CardTitle>
					<Link to={`${pathPrefix}${id}`}>
						<div className="text-xl font-semibold tracking-tight overflow-hidden text-ellipsis">
							{title}
						</div>
					</Link>
				</CardTitle>
			</CardHeader>

			{template && (
				<CardContent className="space-x-2 pr-4 mb-2">
					<div className="text-sm text-gray-800 bg-indigo-100 rounded-full px-3 font-medium inline-block">
						{doc.template?.type}
					</div>
					<span className="text-gray-700 text-sm">{name ?? template.name}</span>
				</CardContent>
			)}

			<CardFooter className="flex items-center gap-2 text-xs">
				<div className="text-stone-500">
					{createdAt && timeSince(createdAt)} ago
				</div>

				{children}
			</CardFooter>
		</Card>
	);
};

interface PublishedDocListItemProps extends DocListItemProps {
	onCopy?: (id: string) => void;
}

export const PublishedDocListItem = ({
	doc,
	onCopy,
	pathPrefix = "/templates/",
}: PublishedDocListItemProps) => {
	const { cache, mutate } = useSWRConfig();

	const { trigger: bookmarkDoc } = useSWRMutation(
		`/a/docs/${doc.id}/bookmark`,
		postFetch,
	);
	const onStarred = async (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
	) => {
		e.preventDefault();
		await bookmarkDoc();

		const pattern = /\/p\/published\/docs\/*/;

		for (const key of cache.keys()) {
			if (!pattern.test(key)) {
				continue;
			}
			mutate(key);
		}
	};
	return (
		<Link to={`${pathPrefix}${doc.id}`}>
			<Card
				className={cn(
					"border border-indigo-100 flex flex-col justify-between shadow hover:border-indigo-500 rounded-lg p-4 gap-2 h-full",
					doc.isExpired ? "opacity-50" : "",
				)}
			>
				<CardHeader>
					<CardTitle>
						<div className="text-xl font-semibold tracking-tight overflow-hidden text-ellipsis">
							{doc.name}
						</div>
					</CardTitle>
				</CardHeader>

				<CardContent className="flex flex-wrap items-center gap-3">
					<TemplateTags doc={doc} noLinks={true} truncate={true} />
				</CardContent>

				<CardFooter className="grow flex flex-col items-start justify-end">
					<div className="flex items-center gap-2">
						{onCopy && (
							<Button
								onClick={async () => {
									onCopy(doc.id);
								}}
								size="sm"
								variant="outline"
							>
								<Share size="15" />
							</Button>
						)}
						<Button
							aria-details="Bookmark this proposal"
							onClick={onStarred}
							size="sm"
							variant="outline"
						>
							<Bookmark
								className={
									doc.bookmarked ? "stroke-green-600 fill-green-500" : ""
								}
								size="15"
							/>
						</Button>
						{doc.isExpired && <div className="text-xs">Deadline passed</div>}
					</div>
				</CardFooter>
			</Card>
		</Link>
	);
};

interface ListDocProps {
	limit?: number;
}

export const ListDocs = ({ limit }: ListDocProps) => {
	const limitCount = (limit ?? 100) + 1;
	const { data: docs } = useSWR<ListDocsRes>(`/a/docs?limit=${limitCount}`, {
		onError: () => {},
	});

	if (!docs) {
		return null;
	}

	return (
		<div>
			<Title desc="Proposals created by you or your team" text="Proposals" />

			{docs.length === 0 && (
				<div className="bg-white border border-dashed rounded-xl p-4 text-xl text-gray-600">
					<InfoIcon className="inline-block mr-2" />
					You have not created any proposals yet.
				</div>
			)}

			<div className="grid gap-cols-1 md:grid-cols-3 gap-4">
				{docs?.slice(0, limit).map((doc) => (
					<DocListItem doc={doc} key={doc.id} />
				))}

				{docs.length === limitCount && (
					<MoreListItem label="Proposals" path="/docs" />
				)}
			</div>
		</div>
	);
};

export const ListFeaturedDocs = () => {
	const { data: docs } = useSWR<ListDocsRes>("/p/featured/docs");

	if (!docs || docs.length === 0) {
		return null;
	}

	return (
		<div>
			<Title
				desc="Real world grant proposals created by our AI"
				text="Sample Proposals"
			/>

			<div className="grid gap-cols-1 md:grid-cols-3 gap-4">
				{docs?.map((doc) => (
					<DocListItem doc={doc} key={doc.id} pathPrefix="/featured/docs/" />
				))}
			</div>
		</div>
	);
};

interface ListDocPublishedProps {
	bookmarked?: boolean;
	categoryId?: string;
	desc?: string;
	hideMore?: boolean;
	id?: string;
	lapsed?: boolean;
	limit?: number;
	publicDocs?: boolean;
	templateIds?: string[];
	title?: string;
}

export const ListPublishedDocs = ({
	bookmarked,
	categoryId,
	desc = "Create proposals from these grant templates created by you or your team",
	hideMore,
	id,
	lapsed,
	limit,
	publicDocs,
	templateIds,
	title = "Grant Application Templates",
}: ListDocPublishedProps) => {
	const limitCount = (limit ?? 200) + 1;

	const queryParams = new URLSearchParams({
		...(categoryId ? { categoryId } : {}),
		...(templateIds ? { templateIds: templateIds.join(",") } : {}),
		...(bookmarked !== undefined
			? { bookmarked: bookmarked ? "true" : "false" }
			: {}),
		lapsed: (lapsed ?? false).toString(),
		limit: limitCount.toString(),
		public: (publicDocs ?? false).toString(),
	});

	const url = `/p/published/docs?${queryParams.toString()}`;

	const { data: docs } = useSWR<ListDocsRes>(url, {
		onError: () => {},
		refreshInterval: 900000,
	});

	const { toast } = useToast();

	const onCopy = async (id: string) => {
		const link = `https://grantorb.com/app/grants/${id}`;
		await navigator.clipboard.writeText(link);

		toast({
			description: "Link copied to clipboard",
			title: "Copied",
		});
	};

	if (!docs || docs.length === 0) {
		return null;
	}

	return (
		<div id={id}>
			<Title desc={desc} text={title} />

			<div className="grid gap-cols-1 md:grid-cols-3 gap-4">
				{docs?.slice(0, limit).map((doc) => (
					<PublishedDocListItem
						doc={doc}
						key={doc.id}
						onCopy={onCopy}
						pathPrefix="/grants/"
					/>
				))}

				{!hideMore && docs.length === limitCount && (
					<MoreListItem label={title} path="/grants" />
				)}
			</div>
		</div>
	);
};
