import { apiPath, cn, parsePath } from "@/lib/utils.js";
import type { ListProductsResponse } from "@/types/payments.js";
import { ChevronDown } from "lucide-react";
import { useState } from "react";
import useSWR from "swr";

import { useUser } from "./hooks/useUser.js";
import { Button } from "./ui/button.js";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card.js";

interface ProductItemProps {
	disabled: boolean;
	product: ListProductsResponse[0];
}

const ProductItem = ({
	disabled,
	product: {
		description,
		featured,
		id,
		items,
		price,
		subscribed,
		subscription,
		title,
	},
}: ProductItemProps) => {
	const { teamId } = parsePath(window.location.pathname);

	const paymentLink = apiPath(`/a/payments/checkout/${id}`, teamId);

	const userPortalLink = apiPath("/a/payments/portal", teamId);

	return (
		<>
			<section
				className={cn([
					"rounded-lg p-3 w-full space-y-4",
					featured ? "border-2 border-indigo-500" : "",
				])}
			>
				<div>
					<div className="flex flex-row md:flex-col items-center md:items-start gap-3 md:gap-0">
						<div className="text-2xl font-medium text-stone-900">
							{description}
						</div>
						<div className="text-xl text-indigo-600 font-semibold">
							{title} {featured && <span>(Popular)</span>}
						</div>
					</div>

					<div className="text-xl font-extrabold text-black pt-3">
						${price} {subscription ? "/ year" : ""}
					</div>
				</div>

				{!disabled ? (
					<Button
						asChild
						className={cn([
							"font-medium  text-xl text-white",
							subscribed ? "" : "bg-indigo-500 hover:bg-indigo-400",
						])}
						size="lg"
					>
						{subscribed ? (
							<a href={userPortalLink}>Update</a>
						) : (
							<a href={paymentLink}>Choose Plan</a>
						)}
					</Button>
				) : (
					<Button
						className="font-medium text-xl text-white bg-gray-300 cursor-not-allowed"
						disabled
					>
						Choose Plan
					</Button>
				)}

				<ol className="list-disc list-inside space-y-2">
					{items.map((item) => (
						<li key={item}>{item}</li>
					))}
				</ol>
			</section>
		</>
	);
};

interface PricingTableProps {
	closed?: boolean;
	embedded?: boolean;
}

export const PricingTable = ({ closed, embedded }: PricingTableProps) => {
	const [buyPanelClosed, setBuyPanelClosed] = useState(closed);

	const { user } = useUser();
	const { data: products } = useSWR<ListProductsResponse>(
		"/p/payments/products",
		{
			refreshInterval: 900000,
			revalidateOnFocus: false,
		},
	);

	const subId = products?.find((p) => p.subscribed)?.id;
	const credits = user?.credits.proposals ?? 0;
	let msg;

	if (!user) {
		msg = "Special early pricing for a limited time only!";
	} else if (credits > 0) {
		msg = `You can create ${credits} document${credits > 1 ? "s" : ""}`;
	} else {
		msg = `Choose a plan to get started`;
	}

	let showBuyPanel;

	if (credits > 0 && buyPanelClosed) {
		showBuyPanel = false;
	} else {
		showBuyPanel = true;
	}

	return (
		<Card
			onClick={() => setBuyPanelClosed(false)}
			className={embedded ? "border-0 shadow-none" : ""}
		>
			<CardHeader>
				<CardTitle className="flex flex-col-reverse md:flex-row items-start md:items-center gap-1 md:gap-2 text-sm md:text-xl font-semibold">
					{!showBuyPanel && (
						<Button
							className="bg-indigo-500 hover:bg-indigo-600 font-semibold text-sm"
							size="sm"
						>
							Show Plans
							<ChevronDown className="ml-3 p-0" size="15" />
						</Button>
					)}

					<div className="text-xl font-medium ml-3">{msg}</div>

					{!user?.isSubscribed && user?.hasDiscount && (
						<div className="rounded-lg text-amber-500 bg-amber-100 px-2 py-1">
							You have a discount, choose a plan to use it
						</div>
					)}
				</CardTitle>
			</CardHeader>

			{showBuyPanel && (
				<CardContent>
					<div className="flex flex-col md:flex-row justify-between gap-4">
						{products?.map((p) => (
							<ProductItem
								disabled={!user || (subId !== undefined && p.id !== subId)}
								key={p.id}
								product={p}
							/>
						))}
					</div>
				</CardContent>
			)}
		</Card>
	);
};
